const DashboardPage = () => {
    return (
       <>
        <div className="manage-card">
            <h1>İnzibatçı panel</h1>
        </div>
       </>
    );
};

export default DashboardPage;